var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-tab", {
    attrs: {
      type: "tabcard",
      align: "left",
      tabItems: _vm.tabItems,
      inlineLabel: true,
    },
    on: {
      "update:tabItems": function ($event) {
        _vm.tabItems = $event
      },
      "update:tab-items": function ($event) {
        _vm.tabItems = $event
      },
      tabClick: _vm.tabClick,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (tab) {
          return [
            _c(tab.component, {
              tag: "component",
              attrs: {
                param: _vm.popupParam,
                dailyCircuit: _vm.dailyCircuit,
                imprTabInfo: _vm.imprTabInfo,
                research: _vm.research,
              },
              on: {
                "update:dailyCircuit": function ($event) {
                  _vm.dailyCircuit = $event
                },
                "update:daily-circuit": function ($event) {
                  _vm.dailyCircuit = $event
                },
                "update:imprTabInfo": function ($event) {
                  _vm.imprTabInfo = $event
                },
                "update:impr-tab-info": function ($event) {
                  _vm.imprTabInfo = $event
                },
                "update:research": function ($event) {
                  _vm.research = $event
                },
                closePopup: _vm.closePopup,
              },
            }),
          ]
        },
      },
    ]),
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v
      },
      expression: "tab",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }