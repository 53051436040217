<template>
  <c-tab
    type="tabcard"
    align="left"
    :tabItems.sync="tabItems"
    :inlineLabel="true"
    v-model="tab"
    @tabClick="tabClick"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
        :param="popupParam"
        :dailyCircuit.sync="dailyCircuit"
        :imprTabInfo.sync="imprTabInfo"
        :research.sync="research"
        @closePopup="closePopup"
      />
    </template>
  </c-tab>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safetyCheckTab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        dccDailyCircuitCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      tabItems: [
        { name: 'base', icon: 'foundation', label: '일상 순회점검 정보', component: () => import(`${'./safetyCheckDetail.vue'}`) },
        { name: 'impr', icon: 'account_tree', label: '개선관리', component: () => import(`${'@/pages/common/ibm/mobileImprTab.vue'}`) },
      ],
      dailyCircuit: {
        dccDailyCircuitCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        checkName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        checkResultList: [], // sheet
        checkImproveList: [], // 개선 list
        checkUserList: [],
      },
      editable: true,
      research: {
        impr: '',
        item: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 45) + 'px';
    },
    imprTabInfo() {
      return {
        // Mobile용 Start
        // customCol에 대한 card title
        requestContentsCols: ['checkItemName'],
        // readOnly용으로 card-detail 내용이 들어감
        customCols: [
         
        ],
        // Mobile용 End
        key: this.popupParam.dccDailyCircuitCheckId, // 
        ibmTaskTypeCd: 'ITT0000210',
        ibmTaskUnderTypeCd: 'ITTU000240',
        requestContents: this.dailyCircuit.checkName,
        disabled: this.dailyCircuit.checkStatusCd === 'MCSC000015' || Boolean(this.dailyCircuit.sysApprovalRequestId),
        isOld: Boolean(this.popupParam.dccDailyCircuitCheckId),
        gridItem: {
          listUrl: selectConfig.sop.dcc.improve.get.url,
          param: {
            dccDailyCircuitCheckId: this.popupParam.dccDailyCircuitCheckId,
            imprFlag: true,
          },
          title: '점검항목별 개선사항 목록', // 점검항목별 개선사항 목록
          merge: [
            { index: 0, colName: "checkItemName" },
          ],
          columns: [
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              align: 'left',
              sortable: false,
            },
            {
              name: 'dayStr',
              field: 'dayStr',
              label: '일',
              align: 'center',
              style: 'width:75px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    closePopup() {
      this.$emit('closePopup')
    },
    tabClick() {
      this.$set(this.research, 'impr', uid())
    }
  }
};
</script>
